import { TransactionTypeIds } from '@shared/models'

export interface TransactionOption {
  value: TransactionTypeIds
  label: string
}

export const TRANSACTIONS_OPTIONS: TransactionOption[] = [
  {
    value: TransactionTypeIds.DEPOSIT,
    label: 'Пополнение средств'
  },
  {
    value: TransactionTypeIds.WITHDRAWAL_FUNDS_RESERVATION,
    label: 'Резервирование средств для вывода'
  },
  {
    value: TransactionTypeIds.WITHDRAWAL_FUNDS_REFUND,
    label: 'Возврат средств после резервирования'
  },
  {
    value: TransactionTypeIds.WITHDRAWAL,
    label: 'Вывод средств'
  },
  {
    value: TransactionTypeIds.EXCHANGE_WRITE_OFF,
    label: 'Списание средств перед обменом'
  },
  {
    value: TransactionTypeIds.EXCHANGE_DEPOSIT,
    label: 'Комиссия за обмен'
  },
  {
    value: TransactionTypeIds.EXCHANGE_PROFIT,
    label: 'Зачисление средств после обмена'
  }
]

export const TRANSACTION_IS_REAL_OPTIONS = [
  {
    label: 'Все',
    value: null
  },
  {
    label: 'Реальная',
    value: true
  },
  {
    label: 'Фейк',
    value: false
  }
]

export const TRANSACTION_DELETED_OPTIONS = [
  {
    label: 'С удаленными',
    value: true
  },
  {
    label: 'Без удаленных',
    value: false
  }
]
