export const CURRENCY_IS_REAL_OPTIONS = [
  {
    label: 'Все',
    value: null
  },
  {
    label: 'Реальная',
    value: true
  },
  {
    label: 'Фейк',
    value: false
  }
]
