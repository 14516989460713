export const PER_PAGE_OPTIONS = [
  {
    label: '10',
    value: 10
  },
  {
    label: '15',
    value: 15
  },
  {
    label: '20',
    value: 20
  },
  {
    label: '25',
    value: 25
  },
  {
    label: '50',
    value: 50
  }
]
